body, html {
    width: 100%;
    height: 100%;
}

.text-link:link, .text-link:visited, .text-link:hover, .text-link:active{
  color: #043D6D;
    text-decoration: none;
}

:root {
    --amplify-primary-color: #043D6D;
    --amplify-primary-tint: #0165A6;
    --amplify-primary-shade: #3383B7;
}


amplify-container {
  height: calc(100vh - 50px);
}